import React from 'react'
import Page from '../../../components/Page'
import Questionaire from '../../../components/ROICalculator/Questionare'
import {ROI_CALCULATOR_DATA} from '../../../components/ROICalculator/ROI-data'
import {ROI_CALCULATOR_DATA_SPECIFIC} from '../../../components/ROICalculator/ROI-dataSpecific'
import {ROIProps} from '../../../components/ROICalculator/types'

const SelectRole = (props: ROIProps) => {
  const {role} = props.params
  let isStartRoot = false
  if (localStorage.getItem('conv_session')) {
    const getCookie = JSON.parse(localStorage.getItem('conv_session'))
    const startURL = getCookie.startUrl
    if (
      startURL.substring(startURL.length - 4) === '/roi' ||
      startURL.substring(startURL.length - 5) === 'users' ||
      startURL.substring(startURL.length - 6) === 'users/'
    ) {
      isStartRoot = true
    }
  }
  let page
  if (isStartRoot) {
    page =
      ROI_CALCULATOR_DATA[
        ROI_CALCULATOR_DATA.userSelect.options.filter(
          option => option.value === role
        )[0].link
      ]
  } else {
    page =
      ROI_CALCULATOR_DATA_SPECIFIC[
        ROI_CALCULATOR_DATA_SPECIFIC.userSelect.options.filter(
          option => option.value === role
        )[0].link
      ]
  }

  const options = page.options.filter(item =>
    item.showTo
      ? item.showTo.includes(role) || item.showTo.includes('all')
      : item
  )

  return (
    <Page>
      <Questionaire page={page} options={options} />
    </Page>
  )
}

export default SelectRole
